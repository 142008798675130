import React from 'react'
import Layout from '../components/layout';
import { Container, Row } from 'react-bootstrap';

export default () => (
  <Layout>
    <Container>
      <Row>
        <h1>Oops, that's a 404</h1>
      </Row>
    </Container>
  </Layout>
)
